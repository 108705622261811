import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usatsylacauga from '../assets/images/usatsylacauga.jpg';
import Billbartvicki from '../assets/images/billbartvicki.jpg';
import Markbart from '../assets/images/markbart.jpg';
import Usatsylacaugatable from '../assets/images/usatsylacaugatable.jpg';

export default function HomePage() {
  return (
    <>
      <SEO title="Home" />
      <div>
        <p>
          Hello! Welcome to the Huddle House Fan Club Website! This site is made
          and kept up to date by the Huddle House Fan Club, a group dedicated to
          the Huddle House. It is designed to inform our adoring public of
          recent developments and adventures of the Huddle House Fan Club of
          Ashland, AL. You can read the "<Link to="/about">About Us</Link>"
          section for details on our group and mission.
        </p>
        <h2 className="center">Our last trip to Sylacauga:</h2>
        <TripHeader>
          <img
            src={Usatsylacauga}
            alt="Bill, Alan, Steven, Tiffany, Mark, Ryan, Bailey, Vicki, Allen Drew, Bart, Kevin, Wade, & Patrick in the Sylacauga Huddle House"
            height="300px"
          />
          <ImageCaption>
            Bill, Alan, Steven, Tiffany, Mark, Ryan, Bailey, Vicki, Allen Drew,
            Bart, Kevin, Wade, & Patrick in the Sylacauga Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Billbartvicki} alt="Bill, Bart, & Vicki" />
            <ImageCaption>Bill, Bart, & Vicki</ImageCaption>
          </div>
          <div>
            <img src={Markbart} alt="Mark & Bart" />
            <ImageCaption>Mark & Bart</ImageCaption>
          </div>
          <div>
            <img
              src={Usatsylacaugatable}
              alt="Wade, Steven, Alan, Tiffany, Kevin, & Allen Drew"
            />
            <ImageCaption>
              Wade, Steven, Alan, Tiffany, Kevin, & Allen Drew
            </ImageCaption>
          </div>
        </ImageGrid>
        <p>
          It has been a while I know, but the Huddle House Fan Club was back at
          their favorite resturant last night. Bailey, Kevin, and Patrick
          introduced ten new members to the Huddle House scene in Sylacauga.
          Alan Cotney, Allen Drew, Wade Drew, Bill Dunn (kid at heart), Vicki
          Cunningham, Bart Holms, Ryan Sligh, Mark Pettus, Tiffany Kennedy, and
          Steven Young went with us for the first time! Check them out in the "
          <Link to="/members">Members</Link>" section. We were a large crowd so
          we couldn't be as personal with the waitress as we like to, but she
          was very nice and friendly. We had great food and got to bring all
          those other friends that have never been. It was great. Peace out!
        </p>
      </div>
    </>
  );
}
