import styled from 'styled-components';

export const ImageGrid = styled.div`
  --columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: 2rem;
  @media (max-width: 700px) {
    --columns: 2;
  }
  @media (max-width: 400px) {
    --columns: 1;
  }
`;

export const ImageCaption = styled.span`
  font-size: 2.5rem;
  text-align: center;
  display: block;
`;
