import styled from 'styled-components';

export const TripHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    font-size: 3.5rem;
  }
  p {
    font-size: 2.5rem;
    margin: 0.3rem;
    /* padding: 0; */
  }
  img {
    margin-bottom: 1rem;
  }
`;
